import { FC } from 'react';
import HeadElement from '@providers/core/HeadElement.provider';
import { NotFound404 } from '@components/404';
import { Layout } from '@components/layouts';
import { defaultSeo } from '@shared/assets/seo';
const Custom404: FC = () => {
  return <Layout>
            <HeadElement seo={defaultSeo} url={`${process.env.WEB_FRONTEND_DOMAIN}/404`} />
            <NotFound404 />
        </Layout>;
};
export default Custom404;