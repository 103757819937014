import styled from 'styled-components';
export const Container = styled.div.withConfig({
  displayName: "OutlineButtonstyle__Container",
  componentId: "sc-mhok24-0"
})(["", " &:hover{", " & a{", " transition:color .2s;}}&:active{", " box-shadow:0px 0px 10px 1px ", ";}@media (max-width:", "){", "}@media (max-width:", "){&:hover{", "}&:active{box-shadow:none;}}"], {
  "display": "flex",
  "height": "3rem",
  "cursor": "pointer",
  "userSelect": "none",
  "alignItems": "center",
  "justifyContent": "center",
  "borderRadius": "100px",
  "borderWidth": "1px",
  "borderStyle": "solid",
  "--tw-border-opacity": "1",
  "borderColor": "rgb(206 206 206 / var(--tw-border-opacity, 1))",
  "paddingLeft": "0.75rem",
  "paddingRight": "0.75rem",
  "paddingTop": "0.375rem",
  "paddingBottom": "0.375rem",
  "fontSize": "28px",
  "fontWeight": "500",
  "lineHeight": "30px",
  "--tw-text-opacity": "1",
  "color": "rgb(88 88 88 / var(--tw-text-opacity, 1))",
  "transitionProperty": "all",
  "transitionTimingFunction": "cubic-bezier(0.4, 0, 0.2, 1)",
  "transitionDuration": "150ms"
}, {
  "--tw-border-opacity": "1",
  "borderColor": "rgb(238 132 60 / var(--tw-border-opacity, 1))",
  "--tw-bg-opacity": "1",
  "backgroundColor": "rgb(238 132 60 / var(--tw-bg-opacity, 1))",
  "--tw-text-opacity": "1",
  "color": "rgb(255 255 255 / var(--tw-text-opacity, 1))",
  "transitionProperty": "all",
  "transitionTimingFunction": "cubic-bezier(0.4, 0, 0.2, 1)",
  "transitionDuration": "150ms"
}, {
  "--tw-text-opacity": "1",
  "color": "rgb(255 255 255 / var(--tw-text-opacity, 1))"
}, {
  "transitionProperty": "all",
  "transitionTimingFunction": "cubic-bezier(0.4, 0, 0.2, 1)",
  "transitionDuration": "150ms"
}, ({
  theme
}) => theme.colors.orange[20], ({
  theme
}) => theme.breakpoint.md, {
  "fontSize": "1.5rem",
  "lineHeight": "2rem"
}, ({
  theme
}) => theme.breakpoint.md, {
  "--tw-border-opacity": "1",
  "borderColor": "rgb(206 206 206 / var(--tw-border-opacity, 1))",
  "--tw-bg-opacity": "1",
  "backgroundColor": "rgb(255 255 255 / var(--tw-bg-opacity, 1))",
  "--tw-text-opacity": "1",
  "color": "rgb(88 88 88 / var(--tw-text-opacity, 1))",
  "transitionProperty": "all",
  "transitionTimingFunction": "cubic-bezier(0.4, 0, 0.2, 1)",
  "transitionDuration": "150ms"
});